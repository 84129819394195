<template>
  <a-card class="page-container">
    <a-tabs v-model="activeTab" @change="handleTabsChange">
      <a-tab-pane
        v-for="item in tabList"
        :key="item.value"
        :tab="item.label"
      />
    </a-tabs>

    <search-form
      :form="searchForm"
      :principal-list="principalList"
      :area-list="areaList"
      :platform-list="platformList"
      @principal-change="handlePrincipalChange"
      @search="handleSearch"
      @reset="handleReset"
    />

    <div class="oper-bar">
      <a-space>
        <a-button
          type="primary"
          :loading="exporting"
          @click="handleExport(1)"
        >
          全部数据导出
        </a-button>
        <a-button
          type="primary"
          :loading="exporting2"
          @click="handleExport(2)"
        >
          导出当前搜索条件数据
        </a-button>
      </a-space>
    </div>

    <data-table
      :loading="loading"
      :data="tableData"
      :pagination="pagination"
      @pagination-change="handlePaginationChange"
      @action-click="handleActionClick"
    />
  </a-card>
</template>

<script>
import SearchForm from './components/SearchForm'
import DataTable from './components/DataTable'
import { platformList } from './constant'
import { downloadExcel } from '@/utils'
import activityApi from '@/api/activity'

export default {
  name: 'actContentListOperAssi',
  components: {
    SearchForm,
    DataTable
  },
  data () {
    return {
      tabList: [
        { label: '全部', value: 0 },
        { label: '按时提交', value: 2 },
        { label: '未提交', value: 1 },
        { label: '延迟提交', value: 3 }
      ],
      activeTab: 0,
      searchForm: {
        principalId: '',
        areaId: '',
        userName: '',
        platform: '',
        dealerName: '',
        submitTime: []
      },
      principalList: [],
      areaList: [
        { label: '全部', value: '' }
      ],
      platformList: [
        { label: '全部', value: '' },
        ...platformList
      ],
      loading: false,
      tableData: [{}],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      exporting: false,
      exporting2: false
    }
  },
  created () {
    this.fetchActivityProductList()
    this.fetchPrincipalList()
  },
  methods: {
    //
    handleTabsChange () {
      this.fetchActivityProductList()
    },
    // 处理查询
    handleSearch () {
      this.pagination.current = 1
      this.fetchActivityProductList()
    },
    // 处理重置
    handleReset () {
      this.pagination.current = 1
      this.fetchActivityProductList()
    },
    // 处理分页改变
    handlePaginationChange (current, pageSize) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.fetchActivityProductList()
    },
    // 获取数据
    async fetchActivityProductList () {
      this.loading = true
      try {
        const { submitTime, ...restSearchForm } = this.searchForm
        const { current, pageSize } = this.pagination
        const params = {
          activityId: this.$route.query.activityId,
          submitStatus: this.activeTab === 0 ? null : this.activeTab,
          ...restSearchForm,
          submitTimeStart: submitTime.length > 0 ? submitTime[0] : '',
          submitTimeEnd: submitTime.length > 0 ? submitTime[1] : '',
          page: current,
          size: pageSize
        }
        const { code, data, message } = await activityApi.fetchActivityProductList(params)

        if (code === 200) {
          this.tableData = data.list
          this.pagination.total = data.total
          this.loading = false
        } else {
          this.$message.error(message)
          this.tableData = []
          this.pagination.total = 0
          this.loading = false
        }
      } catch(e) {
        this.tableData = []
        this.pagination.total = 0
        this.loading = false
      }
    },
    // 获取品牌（主体）列表
    async fetchPrincipalList () {
      try {
        const res = await this.$api.core.TrainingList.getPrincipals()

        if (Array.isArray(res)) {
          this.principalList = [
            { label: '全部', value: '' },
            ...res.map(item => {
              return { label: item.principal_name, value: item.id }
            })
          ]
        } else {
          this.principalList = []
          console.log('获取主体失败')
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 处理品牌（主体）改变
    handlePrincipalChange (val) {
      this.searchForm.areaId = ''
      this.fetchAreaList(val)
    },
    // 获取区域列表
    async fetchAreaList (principalId) {
      try {
        const params = {
          principal_id: principalId  
        }
        const { code, data, message } = await activityApi.fetchAreaList(params)

        if (code === 200) {
          this.areaList = [
            { label: '全部', value: '' },
            ...data.map(item => {
              return { label: item.name, value: item.id }
            })
          ]
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 处理导出
    handleExport (type) {
      if (type === 1) {
        this.exporting = true
        this.exportActivityProductList({
          activityId: this.$route.query.activityId,
          submitStatus: this.activeTab
        })
      }

      if (type === 2) {
        const { submitTime, ...restSearchForm } = this.searchForm

        this.exporting2 = true
        this.exportActivityProductList({
          activityId: this.$route.query.activityId,
          submitStatus: this.activeTab,
          ...restSearchForm,
          submitTimeStart: submitTime.length > 0 ? submitTime[0] : '',
          submitTimeEnd: submitTime.length > 0 ? submitTime[1] : ''
        })
      }
    },
    //
    async exportActivityProductList (params) {
      try {
        const res = await activityApi.exportActivityProductList(params)

        downloadExcel(res, '作品动态数据')
        this.exporting = false
        this.exporting2 = false
      } catch (e) {
        this.exporting = false
        this.exporting2 = false
      }
    },
    // 处理操作
    handleActionClick (type, record) {
      this.$router.push({
        name: 'actManage:contentDetailOperAssi',
        query: {
          activityId: this.$route.query.activityId,
          dealerId: record.dealerId,
          submitStatus: record.submitStatus
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;
}

.oper-bar {
  margin: 10px 0 0;
  text-align: right;
}

.data-table {
  margin: 10px 0 0;
}
</style>
