<template>
  <div class="data-table">
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => index"
      :pagination="false"
    >
      <div slot="order" slot-scope="text, record, index">
        <span>{{ index + 1 }}</span>
      </div>
      <div slot="action" slot-scope="text, record">
        <a
          v-if="record.submitStatus === 1"
          @click="handleActionClick('follow', record)"
        >
          跟踪
        </a>
        <a
          v-if="record.submitStatus === 2 || record.submitStatus === 3"
          @click="handleActionClick('check', record)"
        >
          查看
        </a>
      </div>
    </a-table>
    <base-pagination
      :current-page="pagination.current"
      :page-size="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: '序号',
    key: 'order',
    width: 100,
    scopedSlots: { customRender: 'order' }
  },
  {
    title: '活动名称',
    dataIndex: 'activityName',
    key: 'activityName',
    width: 100
  },
  {
    title: '区域',
    dataIndex: 'areaName',
    key: 'areaName',
    width: 100
  },
  {
    title: '品牌',
    dataIndex: 'principalName',
    key: 'principalName',
    width: 100
  },
  {
    title: '经销商名称',
    dataIndex: 'dealerName',
    key: 'dealerName',
    width: 100
  },
  {
    title: '帐号昵称',
    dataIndex: 'userName',
    key: 'userName',
    width: 100
  },
  {
    title: '平台',
    dataIndex: 'platform',
    key: 'platform',
    width: 100
  },
  {
    title: '平台帐号',
    dataIndex: 'authorName',
    key: 'authorName',
    width: 100
  },
  {
    title: '作品形式',
    dataIndex: 'productType',
    key: 'productType',
    width: 100
  },
  {
    title: '作品信息',
    dataIndex: 'productTitle',
    key: 'productTitle',
    width: 100
  },
  {
    title: '提交时间',
    dataIndex: 'submitTime',
    key: 'submitTime',
    width: 100,
  },
  {
    title: '提交状态',
    dataIndex: 'submitStatusName',
    key: 'submitStatusName',
    width: 100,
  },
  {
    title: '操作',
    key: 'action',
    width: 100,
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'DataTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    pagination: {
      type: Object,
      default () {
        return {
          current: 1,
          pageSize: 10,
          total: 0
        }
      }
    }
  },
  data () {
    return {
      columns
    }
  },
  methods: {
    // 处理操作点击
    handleActionClick(type, record) {
      this.$emit('action-click', type, { ...record })
    },
    // 处理分页改变
    handlePaginationChange(current, pageSize) {
      this.$emit('pagination-change', current, pageSize)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
