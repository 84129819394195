export const areaList = [
  { label: '东区', value: 1 },
  { label: '南区', value: 2 },
  { label: '西区', value: 3 },
  { label: '北区', value: 4 },
  { label: '中区', value: 5 }
]

export const platformList = [
  { label: '抖音', value: '抖音' },
  { label: '快手', value: '快手' }
]
