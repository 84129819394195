<template>
  <a-form-model
    class="search-form"
    ref="form"
    :model="form"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-row :gutter="60">
      <a-col :span="8">
        <a-form-model-item label="品牌" prop="principalId">
          <a-select
            v-model="form.principalId"
            style="width: 100%;"
            @change="handlePrincipalChange"
          >
            <a-select-option
              v-for="item in principalList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="区域" prop="areaId">
          <a-select
            v-model="form.areaId"
            style="width: 100%;"
          >
            <a-select-option
              v-for="item in areaList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="帐号昵称" prop="userName">
          <a-input
            v-model="form.userName"
            placeholder="请输入帐号昵称"
            allow-clear
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="平台" prop="platform">
          <a-select
            v-model="form.platform"
            style="width: 100%;"
          >
            <a-select-option
              v-for="item in platformList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="经销商名称" prop="dealerName">
          <a-input
            v-model="form.dealerName"
            placeholder="请输入经销商名称"
            allow-clear
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="提交时间" prop="submitTime">
          <a-range-picker
            v-model="form.submitTime"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            :show-time="{ format: 'HH:mm:ss' }"
            :placeholder="['开始时间', '结束时间']"
            style="width: 100%;"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item :wrapper-col="wrapperCol2">
          <a-space>
            <a-button type="primary" @click="handleSearch">
              查询
            </a-button>
            <a-button @click="handleReset">
              重置
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
export default {
  name: 'SearchForm',
  props: {
    form: {
      type: Object,
      default () { return {}}
    },
    principalList: {
      type: Array,
      default () { return [] }
    },
    areaList: {
      type: Array,
      default () { return [] }
    },
    platformList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      wrapperCol2: { span: 20, offset: 4 }
    }
  },
  methods: {
    // 处理品牌（主体）改变
    handlePrincipalChange (val) {
      this.$emit('principal-change', val)
    },
    // 处理查询
    handleSearch () {
      this.$emit('search', { ...this.form })
    },
    // 处理重置
    handleReset () {
      this.$refs.form.resetFields()
      this.$emit('reset', { ...this.form })
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
</style>
